.hmc-skeleton {
  border-radius: 3px;
  background: linear-gradient(-45deg, #e7e7e7, #dddddd, #c9c7c7, #c9c7c7);
  background-size: 400% 400%;
  animation: skeleton-gradient-move 3s ease-in-out infinite;
}

@keyframes skeleton-gradient-move {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

h1.hmc-skeleton::before,
h2.hmc-skeleton::before,
h3.hmc-skeleton::before,
h4.hmc-skeleton::before,
h5.hmc-skeleton::before,
h6.hmc-skeleton::before,
p.hmc-skeleton::before,
span.hmc-skeleton::before,
time.hmc-skeleton::before,
a.hmc-skeleton::before {
  /* To emulate height of text. */
  content: ".";
  display: inline-block;
  color: transparent;
}
