@import "./variables.css";
@import "./elements/index.css";
@import "./util.css";

* {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

html,
body {
  min-height: 100vh;
  background-color: var(--background-color-light);
  color: var(--background-contrast-color);
  line-height: var(--line-height);
  margin: 0;
}

a:link {
  color: var(--link-color);
  text-decoration: none;
}
