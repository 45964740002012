:root {
  --mat-option-active-background-color: hsla(218, 100%, 33%, 0.03);
}

.mat-mdc-autocomplete-panel.custom-autocomplete .mdc-list-item--selected.mat-mdc-option.option_active,
.mat-mdc-autocomplete-panel.custom-autocomplete .mat-mdc-option.option_active {
  background-color: var(--mat-option-active-background-color);
  background-image: url("/assets/icons/selected.svg");
  background-repeat: no-repeat;
  background-position: 95% 50%;
}
