.custom-mat-select.mat-mdc-select {
  border: 1px solid var(--ink-secondary-light-color);
  border-radius: var(--border-radius);
  height: 50px;
  padding-inline: 15px;
}

.custom-mat-select.mat-mdc-select-disabled {
  border: 1px solid var(--input-disabled-border-color);
  background-color: var(--input-disabled-background-color);
}

.mat-mdc-select-trigger {
  height: 100%;
}

.mat-mdc-option.option_with-content .mdc-list-item__primary-text {
  width: 100%;
}
