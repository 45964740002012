:root {
  --mat-option-active-background-color: hsla(218, 100%, 33%, 0.03);
}

/* Override material select for the customization. */
.secondary-select.mat-mdc-select {
  border: none;
  padding-inline: 0;
  line-height: initial;
}

.secondary-select.mat-mdc-select .mat-mdc-select-arrow {
  font-style: normal;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  border: none;
  transform: rotate(45deg);
  margin-bottom: 7px;
  margin-left: 15px;
  box-shadow: 2px 2px 0px var(--ink-secondary-color);
}

.secondary-select.mat-mdc-select.is-open .mat-mdc-select-arrow {
  margin-bottom: 0;
  margin-top: 12px;
  transform: rotate(225deg);
  box-shadow: 2px 2px 0px var(--brand-primary-color);
}

.secondary-select.without-arrow .mat-mdc-select-arrow-wrapper {
  display: none;
}

.secondary-select .mat-mdc-select-arrow svg {
  display: none;
}

.secondary-select-panel {
  margin-top: 40px;
}

.secondary-select-panel.mat-mdc-select-panel {
  min-width: 200px;
  padding-top: 20px;
  padding-bottom: 20px;
  max-height: none;
  overflow: initial;
  border-radius: initial;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.14),
              0px 1px 1px rgba(0, 0, 0, 0.12),
              0px 1px 3px rgba(0, 0, 0, 0.18);
}

.secondary-select-panel.mat-mdc-select-panel .secondary-select__options-wrapper {
  max-height: 256px;
  overflow: auto;
}

.secondary-select-panel.mat-mdc-select-panel .mat-mdc-option {
  padding-right: 50px;
}

.secondary-select-panel.mat-mdc-select-panel .mat-mdc-option.mat-mdc-option-active {
  background-color: var(--mat-option-active-background-color);
  background-image: url("/assets/icons/selected.svg");
  background-repeat: no-repeat;
  background-position: 95% 50%;
}

.secondary-select-panel.mat-mdc-select-panel .mat-mdc-option .mdc-list-item__primary-text {
  font-size: var(--font-size-400);
}

.secondary-select-panel.mat-mdc-select-panel .mat-mdc-option.mdc-list-item--selected .mdc-list-item__primary-text {
  font-weight: 700;
}
