:root {

  --brand-primary-color: hsla(218, 100%, 33%, 1);
  --brand-primary-light-color: hsla(218.2, 67%, 45.1%, 1);
  --brand-primary-dark-color: hsla(218, 86.2%, 25.5%, 1);

  --brand-secondary-color: hsla(218, 100%, 95%, 1);
  --brand-secondary-light-color: hsla(216.9, 44.8%, 94.3%, 1);
  --brand-secondary-dark-color: hsla(216, 68.6%, 90%, 1);

  --brand-tertiary-color: hsla(186, 87%, 43%, 1);
  --brand-tertiary-light-color: hsla(186.2, 78.1%, 48.4%, 1);
  --brand-tertiary-dark-color: hsla(185.7,81.3%,37.8%, 1);

  --brand-accent-color: hsla(222, 81%, 53%, 1);

  --priority-color-high: hsla(352, 84%, 61%, 1);
  --priority-color-medium: hsla(43, 97%, 74%, 1);
  --priority-color-low: hsla(209, 63%, 79%, 1);

  --status-color-new: hsla(186, 87%, 43%, 1);
  --status-color-reopened: hsla(43, 97%, 74%, 1);
  --status-color-in-progress: hsla(222, 81%, 53%, 1);
  --status-color-pending: hsla(82, 96%, 43%, 1);
  --status-color-closed: hsla(0, 0%, 29%, 1);
  --status-color-closed-secondary: hsla(0, 0%, 93%, 1);

  --background-color: hsla(240, 25%, 98%, 1);
  --background-color-light: hsla(0, 0%, 100%, 1);

  --ink-primary-color: hsla(0, 0%, 0%, 0.87);
  --ink-primary-light-color: hsla(0, 0%, 0%, 0.54);
  --ink-primary-dark-color: hsla(0, 0%, 0%, 1);
  --ink-primary-contrast-color: hsla(0, 0%, 100%, 1);

  --ink-secondary-color: hsla(0, 0%, 0%, 0.38);
  --ink-secondary-light-color: hsla(0, 0%, 0%, 0.12);
  --ink-secondary-dark-color: hsla(0, 0%, 0%, 0.7);

  --input-border-color: hsla(0, 0%, 0%, 0.2);
  --input-disabled-border-color: hsla(0, 0%, 0%, 0.12);
  --input-disabled-background-color: hsla(0, 0%, 93%, 1);

  --error-color: hsla(348, 100%, 55%, 1);
  --link-color: hsla(222, 81%, 53%, 1);

  --drag-background-color:  hsl(209, 80%, 90%);
  --drag-over-background-color:  hsl(137, 84%, 93%);
  --drag-border-color:  hsl(209, 39%, 48%);
  --drag-over-border-color:  hsl(139, 17%, 32%);

  --font-size-800: 1.5rem;
  --font-size-700: 1.12rem;
  --font-size-600: 1.06rem;
  --font-size-500: 1rem;
  --font-size-400: 0.87rem;
  --font-size-300: 0.75rem;

  --line-height: 1.5rem;

  --border-radius: 4px;

  --header-height: 65px;
  --filters-height: 75px;

}
