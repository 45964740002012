button.button,
a.button {
  padding: 10px;
  border: none;
  border-radius: var(--border-radius);
  font-size: var(--font-size-500);
  font-weight: bold;
  line-height: inherit;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: background-color, opacity 300ms ease-in-out;
}

button.primary,
a.primary {
  --hover-background-color: hsla(218, 70%, 41%, 1);
  --active-background-color: hsla(218, 51%, 49%, 1);
  --disabled-font-color: hsla(0, 0%, 100%, 0.5);

  padding: 10px;
  background-color: var(--brand-primary-color);
  border: none;
  border-radius: var(--border-radius);
  font-size: var(--font-size-500);
  font-weight: bold;
  line-height: inherit;
  color: var(--ink-primary-contrast-color);
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: background-color, opacity 300ms ease-in-out;
}

button.primary:hover,
a.primary:hover {
  background-color: var(--hover-background-color);
}

button.primary:active,
a.primary:active {
  background-color: var(--active-background-color);
}

button.primary:disabled,
a.primary:disabled {
  background-color: var(--ink-primary-light-color);
  color: var(--disabled-font-color);
  opacity: 0.5;
  cursor: not-allowed;
}

button.hmc-loading {
  cursor: wait;
}

button.secondary,
a.secondary {
  --disabled-font-color: hsla(0, 0%, 100%, 0.5);

  padding: 10px;
  background-color: var(--brand-secondary-color);
  border: none;
  border-radius: var(--border-radius);
  font-size: var(--font-size-500);
  font-weight: bold;
  line-height: inherit;
  color: var(--brand-primary-color);
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: background-color, opacity 300ms ease-in-out;
}

button.secondary:hover,
a.secondary:hover {
  background-color: var(--brand-secondary-light-color);
}

button.secondary:active,
a.secondary:active {
  background-color: var(--brand-secondary-dark-color);
}

button.secondary:disabled,
a.secondary:disabled {
  background-color: var(--ink-primary-light-color);
  color: var(--disabled-font-color);
  opacity: 0.5;
  cursor: not-allowed;
}

button.outlined,
a.outlined {
  padding: 10px;
  border-radius: var(--border-radius);
  font-size: var(--font-size-400);
  line-height: 1rem;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid var(--brand-primary-color);
  color: var(--brand-primary-color);
  text-align: center;
  transition: background-color, opacity 300ms ease-in-out;
}

button.outlined:hover,
a.outlined:hover {
  background-color: var(--brand-secondary-light-color);
}

button.outlined:active,
a.outlined:active {
  background-color: var(--brand-secondary-dark-color);
}

button.outlined:disabled,
a.outlined:disabled {
  background-color: var(--ink-primary-light-color);
  color: var(--disabled-font-color);
  opacity: 0.5;
  cursor: not-allowed;
}

button.faint,
a.faint {
  padding: 10px;
  background-color: var(--ink-secondary-light-color);
  border: none;
  border-radius: var(--border-radius);
  font-size: var(--font-size-500);
  font-weight: bold;
  line-height: inherit;
  color: var(--ink-secondary-dark-color);
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: background-color, opacity 300ms ease-in-out;
}

button.faint:hover,
a.faint:hover {
  background-color: hsla(0, 0%, 0%, 0.10);
}

button.faint:active,
a.faint:active {
  background-color: hsla(0, 0%, 0%, 0.15);
}

button.faint:disabled,
a.faint:disabled {
  background-color: hsla(0, 0%, 0%, 0.08);
  color: var(--ink-secondary-light-color);
  opacity: 0.5;
  cursor: not-allowed;
}
